import { Injectable } from '@angular/core';
import { BasePopupService, PopupInput } from '@ui/base.popup.service';
import { PopUpInput } from '@ui/base.popup.directive';
import { Subject } from 'rxjs';

export interface DDMBase {
  display: string;
  label?: string;
  label2?: string;
}

export interface DDMAction extends DDMBase {
  action: () => unknown;

  icon?: string;
  iconColor?: string;
  color?: string;
}

export interface DDMSelect<Entity extends any> extends DDMBase {
  photoUrl?: string;
  isSelected?: boolean;
  entity: Entity;

  error?: boolean;
}

export type DDMOption<Entity extends any = any> = Partial<DDMAction & DDMSelect<Entity>> & DDMBase;

export interface DDMOptions extends PopUpInput<DDMOption[]> {
  fitWidth?: boolean;
  maxHeightRem?: number;
  scale?: number;

  showPhotos?: boolean;
  photoSize?: number;
  photoRadius?: string;
}

type DataUpdate<T> = Omit<PopupInput<T, any>, 'callback'>;

@Injectable({ providedIn: 'root' })
export class OptionsService extends BasePopupService<DDMOptions, DDMOption | void | undefined> {

  onUpdate = new Subject<DataUpdate<DDMOptions>>();

  updateData(data: DDMOptions): void {
    console.log('input (update): ', data);
    this.onUpdate.next({ data });
  }
}
